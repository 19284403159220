@use '@angular/material' as mat;
@use 'abstracts/variables';
@use 'abstracts/functions';
@use 'abstracts/mixins';
@use 'sass:map';

@include mat.core();

.mat-mdc-dialog-surface,
.mat-mdc-menu-panel,
.mat-mdc-select-panel {
  background-color: variables.$basic-background !important;
}

.mat-mdc-select-panel {
  padding: unset !important;
  border-radius: variables.$radius-small !important;
}

.mat-mdc-text-field-wrapper {
  padding: 0 0 1.25rem !important;
}
.mat-mdc-input-element {
  font: inherit;
  background: transparent;
  color: currentColor;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
  box-sizing: content-box;
  margin-top: -0.0625em;
  font-weight: 400;
}
.mdc-radio__inner-circle {
  color: variables.$basic-stroke;
}

.mat-mdc-radio-button .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  color: variables.$basic-stroke;
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

.quantity-button {
  .mat-mdc-button-wrapper {
    line-height: initial;
  }
}

.mat-mdc-icon-button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 !important;

  .mat-mdc-button-wrapper {
    line-height: inherit;
  }
}

mat-checkbox {
  --mdc-checkbox-selected-pressed-icon-color: var(--mode-basic-stroke);
  --mdc-checkbox-selected-focus-icon-color: var(--mode-basic-stroke);
  --mdc-checkbox-selected-hover-icon-color: var(--mode-basic-stroke);
  --mdc-checkbox-selected-icon-color: var(--mode-basic-stroke);
}

.mdc-checkbox--disabled {
  opacity: 0.33;
}

.mat-mdc-option:hover,
.mat-mdc-option-active {
  background: variables.$basic-background-subtle !important;
}

.mat-mdc-option .mat-pseudo-checkbox {
  display: none;
}

.mat-mdc-option-active {
  color: variables.$basic-stroke !important;
}

.mat-mdc-checkbox {
  .mdc-checkbox {
    padding-right: 4px !important;
  }

  .mat-mdc-checkbox-layout .mat-mdc-checkbox-label {
    line-height: unset;
  }

  .mat-mdc-checkbox.mat-mdc-primary .mat-mdc-checkbox-checkmark {
    top: -0.125rem;
    left: -0.0625rem;
    width: 110%;
  }

  &-checked {
    .mat-mdc-checkbox-checkmark-path {
      stroke-width: 0.25rem;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }

  &.mat-mdc-primary {
    .mat-mdc-checkbox-checkmark-path {
      stroke: variables.$basic-text-on-accent !important;
    }
  }
}

.mat-mdc-radio-button {
  .mat-mdc-radio-label {
    white-space: normal !important;
  }

  &.mat-mdc-radio-disabled {
    opacity: 0.33;
  }
}

.box-radio-button {
  &.mat-mdc-radio-button {
    display: block;
    padding: 0 0.875rem;

    .mat-mdc-radio-label,
    .mat-mdc-radio-label-content .mat-mdc-radio-button {
      width: 100%;

      .mat-mdc-radio-label-content {
        width: 100%;
      }
    }
  }
}

.mat-mdc-menu-item .menu-item-image {
  height: 1.375rem;
  width: 1.375rem;
  margin-right: 0.75rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.mat-mdc-menu-item {
  &:hover {
    background: variables.$basic-background-subtle !important;
  }
}

.mat-mdc-menu-panel {
  max-width: unset !important;

  &.discover-shops-menu-panel {
    max-width: unset;
  }

  &.header-menu {
    width: 15rem;
    position: absolute;
    right: functions.rem(100px);
    margin-top: 16px;
    box-shadow: variables.$elevation4;

    .mat-mdc-menu-item {
      display: flex;
      align-items: center;

      span {
        line-height: 1rem;
        display: flex;
        align-items: center;
        padding: 0 functions.rem(16px);
        text-align: left;
      }
    }
  }
}

// must override background-color because it is inline styling from material
.mat-mdc-form-field-appearance-legacy.mat-mdc-form-field-disabled .mat-mdc-form-field-underline {
  background-image: unset;
  background-color: variables.$basic-text-recessive !important;
}

.mat-mdc-select {
  font-size: 0.875rem !important;
  line-height: 1rem !important;
}

.mdc-line-ripple:before {
  border-bottom-color: variables.$basic-text-recessive !important;
  border-bottom-width: functions.rem(1px) !important;
}

mat-form-field {
  --mdc-filled-text-field-error-label-text-color: var(--colours-functional-destructive-standard);
  --mdc-filled-text-field-error-focus-label-text-color: var(--colours-functional-destructive-standard);
  --mdc-filled-text-field-error-active-indicator-color: var(--colours-functional-destructive-standard);
  --mdc-filled-text-field-error-focus-active-indicator-color: var(--colours-functional-destructive-standard);
  --mdc-filled-text-field-error-hover-label-text-color: var(--colours-functional-destructive-standard);
  --mat-form-field-error-text-color: var(--colours-functional-destructive-standard);
  --mdc-filled-text-field-error-caret-color: var(--colours-functional-destructive-standard);

  --mdc-filled-text-field-caret-color: var(--mode-basic-stroke);
  --mdc-filled-text-field-focus-label-text-color: var(--mode-basic-stroke);
  --mdc-filled-text-field-focus-active-indicator-color: var(--mode-basic-stroke);

  --mat-form-field-container-height: 16px;
}

.mdc-text-field {
  overflow: unset !important;
  align-items: center !important;
  padding: 0.25rem 0 !important;
}

.mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input {
  padding: 0 !important;
}

.mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
  --mdc-filled-text-field-disabled-input-text-color: var(--colours-interaction-states-disabled-text-disabled);
}

.mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input {
  color: var(--mode-basic-text) !important;
}

.mat-mdc-form-field-error-wrapper,
.mat-mdc-form-field-hint-wrapper {
  padding: 0 0 0.25rem !important;
  font-style: italic;
  font-size: 0.625rem !important;
}

.mat-mdc-form-field {
  font-size: 0.875rem !important;
  line-height: 1rem !important;

  .mdc-text-field {
    padding: 0 !important;
  }

  &-infix {
    border-top: 0.84375em solid transparent !important;
    padding: functions.rem(6px) 0 !important;
  }

  &.mat-mdc-form-field-appearance-outline {
    .mat-mdc-form-field-infix {
      border-width: 3px;
    }
  }

  .mat-mdc-form-field-prefix {
    color: variables.$basic-text-recessive;
    align-self: flex-end;
    margin-bottom: 0.1875rem;
    margin-right: 0.5rem;
    height: 1.5rem;

    .mat-mdc-icon {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }

  .mat-mdc-form-field-prefix .mat-mdc-icon-button,
  .mat-mdc-form-field-suffix .mat-mdc-icon-button {
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1.25rem !important;
    align-self: flex-end;
    margin-bottom: 0.1875rem;
  }

  .mat-mdc-input-element {
    @include mixins.font-weight(normal);

    &::placeholder {
      @include mixins.font-weight(normal);
    }
  }

  .mat-mdc-error {
    font-style: italic;
  }

  &.mat-mdc-focused {
    .mat-mdc-form-field-required-marker {
      color: variables.$basic-accent;
    }
  }
}

.mat-mdc-form-field.bordered {
  .mat-mdc-form-field-flex {
    display: flex;
    align-items: center;
    min-height: 2.5em;
    border: 1px solid #aaa9ab;
    border-radius: variables.$radius-extra-small;
  }

  mat-mdc-icon.mat-mdc-icon {
    margin: 0 0.5rem;
  }

  &.mat-mdc-focused {
    mat-mdc-icon.mat-mdc-icon {
      color: variables.$basic-accent;
    }

    .mat-mdc-form-field-required-marker {
      color: variables.$basic-accent;
    }

    .mat-mdc-form-field-flex {
      border-color: variables.$basic-accent;
    }
  }

  .mat-mdc-form-field-infix {
    border: 0;
    padding: 0;
  }

  .mat-mdc-form-field-suffix button {
    height: 100% !important;
  }
}

.mat-expansion-panel {
  box-shadow: none !important;

  .mat-expansion-panel-header {
    padding: 0 1rem;
    color: variables.$basic-text;
    height: functions.rem(48px) !important;

    &:hover {
      background-color: variables.$basic-background-subtle !important;
    }

    &[aria-disabled='true'] {
      color: variables.$basic-text;
    }
  }

  .mat-expansion-panel-body {
    padding: 0 1rem;
  }

  .mat-expansion-indicator {
    &::after {
      border-width: 0 0.125rem 0.125rem 0;
      padding: 0.25rem;
      vertical-align: baseline;
    }
  }

  &.offer-wizard-group {
    .mat-expansion-panel-header {
      height: functions.rem(58px) !important;
    }
  }

  &.checkout-expansion-panel {
    .mat-expansion-panel-header {
      padding-left: 0 !important;
      padding-right: 0.375rem !important;
      height: functions.rem(64px) !important;
    }

    .mat-expansion-panel-body {
      padding: 0 !important;
    }

    .mat-expansion-indicator {
      &::after {
        color: variables.$basic-accent;
        border-width: 0 0.125rem 0.125rem 0;
        position: relative;
        top: -0.25rem;
      }
    }
  }

  &.faq-expansion-panel {
    .mat-expansion-panel-header,
    .mat-expansion-panel-body {
      padding: 0 1rem 0 0.5rem;
    }
  }
}

.mat-mdc-drawer-container {
  background-color: transparent;
}

.mdc-snackbar {
  background-color: variables.$basic-background-subtle;
}

.mat-mdc-snack-bar-action {
  color: variables.$basic-stroke !important;
}

%material-icons_baseline {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.material-icons {
  font-family: 'Material Icons';
  @extend %material-icons_baseline;
}

.material-icons-round {
  font-family: 'Material Icons Round';
  @extend %material-icons_baseline;
}

.cdk-overlay-dark-backdrop {
  background: variables.$basic-modal-overlay;
}

.cdk-overlay-transparent-backdrop {
  background: transparent;
}

div.mat-mdc-autocomplete-panel {
  --mat-autocomplete-background-color: var(--mode-basic-background);
  border-radius: variables.$radius-extra-small !important;
  padding: unset !important;
  margin: functions.rem(8px) 0 !important;
}

mat-slide-toggle {
  --mdc-switch-handle-shape: 50%;
  --mdc-switch-track-width: 32px;
  --mat-switch-with-icon-handle-size: 16px;
  --mdc-switch-state-layer-size: 16px;
  --mdc-switch-track-height: 12px;
  --mdc-switch-track-shape: 8px;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-visible-track-transition: opacity 75ms;
  --mat-switch-hidden-track-opacity: 0;
  --mat-switch-hidden-track-transition: opacity 75ms;
  --mat-switch-track-outline-width: 0;

  --mdc-switch-unselected-icon-size: 16px;
  --mdc-switch-unselected-track-color: var(--mode-interaction-states-hover-stroke-subtle-hover);
  --mdc-switch-unselected-icon-color: #efeef1;
  --mdc-switch-unselected-handle-color: #efeef1;
  --mdc-switch-unselected-hover-state-layer-color: var(--mode-interaction-states-hover-stroke-subtle-hover);
  --mdc-switch-unselected-pressed-state-layer-color: var(--mode-interaction-states-hover-stroke-subtle-hover);
  --mdc-switch-unselected-focus-handle-color: #efeef1;
  --mdc-switch-unselected-hover-handle-color: #efeef1;
  --mdc-switch-unselected-focus-track-color: var(--mode-interaction-states-hover-stroke-subtle-hover);
  --mdc-switch-unselected-hover-track-color: var(--mode-interaction-states-hover-stroke-subtle-hover);
  --mdc-switch-unselected-pressed-track-color: var(--mode-interaction-states-hover-stroke-subtle-hover);

  --mdc-switch-selected-icon-size: 16px;
  --mat-switch-selected-track-outline-width: 0;
  --mdc-switch-selected-track-color: var(--mode-shades-accent-shades-accent-extra-dominant);
  --mdc-switch-selected-icon-color: var(--mode-basic-stroke);
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 16px;
  --mdc-switch-selected-handle-color: var(--mode-basic-stroke);
  --mdc-switch-selected-hover-state-layer-color: var(--mode-shades-accent-shades-accent-extra-dominant);
  --mdc-switch-selected-pressed-state-layer-color: var(--mode-shades-accent-shades-accent-extra-dominant);
  --mdc-switch-selected-focus-handle-color: var(--mode-basic-stroke);
  --mdc-switch-selected-hover-handle-color: var(--mode-basic-stroke);
  --mdc-switch-selected-focus-track-color: var(--mode-shades-accent-shades-accent-extra-dominant);
  --mdc-switch-selected-hover-track-color: var(--mode-shades-accent-shades-accent-extra-dominant);
  --mdc-switch-selected-pressed-track-color: var(--mode-shades-accent-shades-accent-extra-dominant);

  --mdc-switch-disabled-unselected-track-color: var(--mode-interaction-states-hover-stroke-subtle-hover);
  --mdc-switch-disabled-unselected-handle-color: #efeef1;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-disabled-selected-handle-color: var(--mode-basic-stroke);
  --mdc-switch-disabled-selected-track-color: var(--mode-shades-accent-shades-accent-extra-dominant);
  --mat-switch-disabled-selected-track-outline-color: transparent;
}

.mat-mdc-slide-toggle .mdc-switch--unselected .mdc-switch__handle:has(.mdc-switch__icons),
.mat-mdc-slide-toggle .mdc-switch--selected .mdc-switch__handle:has(.mdc-switch__icons) {
  border-radius: 50%;
}

.mat-mdc-slide-toggle .mdc-switch--selected .mdc-switch__handle:has(.mdc-switch__icons) {
  border: 1px solid variables.$shades-accent-shades-accent-extra-dominant;
}

.mat-mdc-slide-toggle .mdc-switch--unselected .mdc-switch__handle:has(.mdc-switch__icons) {
  border: 1px solid variables.$interaction-states-hover-stroke-subtle-hover;
}

.mdc-switch__handle::before,
.mdc-switch__handle::after {
  border-radius: 50% !important;
  border: none !important;
}

.mdc-switch--selected .mdc-switch__icon,
.mdc-switch--unselected .mdc-switch__icon {
  fill: none !important;
}

.mdc-switch.mdc-switch--disabled {
  opacity: 0.38;
}

mat-dialog-container {
  --mat-dialog-transition-duration: 150ms !important;
}

mat-bottom-sheet-container {
  --mat-bottom-sheet-container-background-color: var(--mode-basic-background);
  padding: 0 !important;
}

.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none !important;
}

.mat-mdc-radio-button .mdc-radio {
  padding-right: 4px !important;
}

.mat-expansion-panel-header {
  padding: 0 16px !important;
  --mat-expansion-header-expanded-state-height: 64px;
  --mat-expansion-header-collapsed-state-height: 64px;
  --mat-expansion-header-hover-state-layer-color: var(--mode-basic-background-subtle);
}

.mat-expansion-panel-header.mat-expanded:hover {
  background-color: variables.$basic-background-subtle !important;
}

.mat-expansion-panel-body {
  padding: 0 16px 16px !important;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  --mat-option-hover-state-layer-color: var(--mode-basic-background-subtle);
}

.mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
}
