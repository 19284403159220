@use 'abstracts/variables';
@use 'abstracts/functions';
@use 'abstracts/mixins';

.legal {
  &-section {
    margin-bottom: functions.rem(32px);
  }

  &-header {
    line-height: functions.rem(24px);
  }

  &-li {
    list-style: disc;
  }

  &-table {
    border-collapse: collapse;
    margin: functions.rem(8px) 0;

    &-th {
      @include mixins.font-weight(medium);
    }

    &-th,
    &-td {
      border: functions.rem(1px) solid variables.$basic-stroke;
      text-align: center;
      font-size: functions.rem(12px);
      color: variables.$basic-text-recessive;
      padding: functions.rem(4px);
    }
  }
}
