@use 'abstracts/variables';
@use 'abstracts/mixins';
@use 'abstracts/functions';
@use 'sass:meta';

@include meta.load-css('../../../../../../node_modules/swiper/swiper.min.css');
@include meta.load-css('../../../../../../node_modules/swiper/modules/a11y.css');
@include meta.load-css('../../../../../../node_modules/swiper/modules/navigation.css');
@include meta.load-css('../../../../../../node_modules/swiper/modules/pagination.css');

.swiper-pagination-bullet-active {
  background: variables.$shades-neutral-shades-700;
}

.swiper {
  [hidden] {
    display: none;
  }
}

.box-swiper {
  position: relative;

  .box-swiper-button-prev,
  .box-swiper-button-next,
  .box-swiper-button-inline-prev,
  .box-swiper-button-inline-next {
    width: functions.rem(32px);
    height: functions.rem(32px);
    line-height: functions.rem(32px);
    margin: 0 functions.rem(8px);
    background-color: variables.$basic-background-subtle;
    z-index: 1;
    visibility: visible;
    opacity: 1;
    cursor: pointer;

    &[hidden='true'] {
      visibility: hidden;
    }

    &.swiper-button-disabled {
      opacity: 0.5;
      cursor: unset;
    }

    .mat-icon {
      color: variables.$basic-text;
    }

    @include mixins.media-breakpoint-max(variables.$media-md) {
      visibility: hidden;
    }
  }

  .box-swiper-button-inline-prev,
  .box-swiper-button-inline-next {
    margin: unset;
    position: absolute;
    top: calc(50% - 1rem);
    z-index: 2;
  }

  .box-swiper-button-inline-prev {
    left: functions.rem(-16px);
  }

  .box-swiper-button-inline-next {
    right: functions.rem(-16px);
  }

  /* Pagination */
  --swiper-pagination-bullet-size: #{functions.rem(4px)};
  --swiper-pagination-bullet-inactive-color: #{variables.$shades-neutral-shades-400};
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bottom: 0;
  --swiper-pagination-bullet-horizontal-gap: #{functions.rem(2px)};
  --swiper-pagination-bullet-vertical-gap: #{functions.rem(2px)};

  .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    &.swiper-pagination-lock {
      display: none;
    }

    &.swiper-pagination-bullets {
      &.swiper-pagination-horizontal {
        position: relative;
        width: 100%;
        padding: functions.rem(2px) functions.rem(4px);
        margin: functions.rem(8px) 0;
      }
    }

    &-bullet {
      scale: 1;
      width: functions.rem(6px);
      height: functions.rem(6px);
      transition: background-color 150ms variables.$decelerate-easing, scale 150ms variables.$decelerate-easing;

      &-active {
        width: functions.rem(8px);
        height: functions.rem(8px);
      }
    }
  }

  .swiper-horizontal {
    padding: functions.rem(4px);
    margin-left: functions.rem(-4px);
    margin-top: functions.rem(-4px);
  }
}
