@use 'abstracts/variables';
@use 'abstracts/mixins';
@use 'abstracts/functions';

.cosid_container {
  white-space: normal;
}

#cosid-login-container {
  .qr-code {
    display: none !important;

    & + .separator {
      display: none !important;
    }
  }
}

// this :not selector is used to only apply changes to the home popup and not the one in the landing page
#cosid_dropdown {
  &.customer_foodboxweb:not(.login-container) {
    width: 20rem !important;
    max-width: 100vw !important;
    background-color: variables.$basic-background !important;

    &:before {
      border-bottom: 13px solid rgba(0, 0, 0, 0.15) !important;
    }

    &:after {
      border-bottom: 12px solid variables.$basic-background !important;
    }
  }

  .cosid-dropdown-top {
    &-right a {
      color: variables.$basic-text !important;
    }
  }

  .cosid-dropdown-bottom {
    border-top: 1px solid variables.$basic-background-subtle !important;
    background-color: variables.$basic-background-subtle !important;

    & a {
      border: 1px solid variables.$basic-accent !important;
      background: variables.$basic-accent !important;
      color: variables.$basic-text-on-accent !important;
    }
  }
}

#cosid_md_login {
  white-space: initial;

  &.dropdown {
    position: relative;
    height: 100%;
    z-index: 3;

    &.open {
      z-index: 4;
    }
  }

  // in the landing page
  &.landing-hero-login {
    #cosid_dropdown.login-container {
      top: 50px;
    }
  }
}

// this code is responsible for both the popup in home page but also in the landing too!
#cosid_md_login.my-account.open > #cosid_dropdown,
#cosid_dropdown {
  @include mixins.media-breakpoint-only(variables.$media-sm) {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;

    // get rid of the bookmark pointer in mobile view
    &:before,
    &:after {
      content: unset;
    }
  }
}

#cosid_dropdown.cosid_container .arrow-box:after {
  @include mixins.media-breakpoint-only(variables.$media-sm) {
    content: none;
  }
}
