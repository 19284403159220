@use 'components/box-badge';
@use 'components/box-nav';
@use 'components/block-editor';
@use 'components/box-swiper-section';
@use 'abstracts/animations';
@use 'themes/material-theme';

/*
@include meta.load-css should be used for the following,
since we don't need their namespace and they don't contain any mixins/functions for us to use.
However an error seems to appear
https://lightrun.com/answers/sass-sass-bogus-target-selector-not-found-error-with-metaload-css
*/

// vendors
@use 'vendors/cosmote-id';

// base
@use 'base/reset';
@use 'base/typography';

// layout
@use 'layout/box-grid';
@use 'layout/box-pages';

// components
@use 'components/box-banner';
@use 'components/box-bottom-sheet';
@use 'components/box-button';
@use 'components/box-divider';
@use 'components/box-expansion-panel';
@use 'components/box-hoverable';
@use 'components/box-image';
@use 'components/box-legal';
@use 'components/box-myo';
@use 'components/box-preloader';
@use 'components/box-skeleton';
@use 'components/box-swiper';
@use 'components/box-dialog';
@use 'components/circular-progress';

@include box-badge.core-styles();
@include box-nav.core-styles();
@include block-editor.core-styles();
@include box-swiper-section.core-styles();
@include animations.animation-keyframes();
