@use 'abstracts/functions';

.offer-wizard-group-products {
  .mdc-label,
  .mdc-form-field {
    width: 100%;
  }

  .mat-mdc-radio-button {
    width: 100%;

    .mdc-form-field {
      width: 100%;
    }
  }
}

.selection-choices {
  .mdc-label,
  .mdc-form-field,
  .mat-mdc-checkbox,
  .mat-checkbox-layout,
  .mat-checkbox-label,
  .mat-checkbox-label-content {
    width: 100%;
  }

  .mat-checkbox-inner-container {
    margin-top: functions.rem(8px);
    margin-bottom: auto;
  }

  .mat-radio-container {
    margin-top: functions.rem(4px);
    margin-bottom: auto;
  }

  .mdc-radio {
    margin-bottom: functions.rem(2px);
  }

  .mat-mdc-radio-button {
    width: 100%;

    .mdc-form-field {
      width: 100%;
    }
  }
}
