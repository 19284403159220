@use 'abstracts/variables';
@use 'abstracts/mixins';
@use 'abstracts/functions';

.page-wrapper {
  display: block;
  min-height: calc(100vh - #{variables.$box-header-height});
}

.container {
  @include mixins.box-container();
}

.section {
  @include mixins.box-section();
}

.page-container {
  padding: functions.rem(12px) 0;

  @include mixins.media-breakpoint-min(variables.$media-sm) {
    padding: functions.rem(16px) 0;
  }

  @include mixins.media-breakpoint-min(variables.$media-md) {
    padding: functions.rem(4px) 0;
  }

  @include mixins.media-breakpoint-min(variables.$media-lg) {
    padding: functions.rem(20px) 0;
  }
}

.page-section {
  padding: functions.rem(12px) functions.rem(24px);

  @include mixins.media-breakpoint-min(variables.$media-sm) {
    padding: functions.rem(16px) functions.rem(32px);
  }

  @include mixins.media-breakpoint-min(variables.$media-md) {
    padding: functions.rem(20px) functions.rem(48px);
  }
}
