@use 'abstracts/variables';
@use 'abstracts/functions';
@use 'abstracts/mixins';

@mixin core-styles() {
  @include box-nav-styles();
  @include box-nav-item-styles();
}

@mixin box-nav-styles {
  .box-nav {
    display: flex;
    align-items: center;
    border-radius: variables.$radius-extra-small;
    overflow: hidden;
    height: functions.rem(44px);

    &.box-nav-elevated {
      border: functions.rem(1px) solid variables.$basic-stroke-subtle;
    }

    &.box-nav-separated {
      .box-nav-item {
        &::after {
          content: '';
          position: absolute;
          right: 0;
          height: 55%;
          width: 1px;
          background-color: variables.$basic-stroke-subtle;
        }

        &:last-child {
          &::after {
            visibility: hidden;
          }
        }
      }
    }
  }
}

@mixin box-nav-item-styles {
  .box-nav-item {
    position: relative;
    display: inline-flex;
    padding: 0 functions.rem(16px);
    height: 100%;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    user-select: none;
    transition: opacity 100ms variables.$standard-easing, color 100ms variables.$standard-easing;
    outline: 0;
    flex: 1 0 0;
    cursor: pointer;

    font-size: functions.rem(12px);
    @include mixins.font-weight(medium);
    line-height: functions.rem(14px);
    text-align: center;

    &,
    svg {
      color: variables.$basic-text-recessive;
      fill: variables.$basic-text-recessive;
      stroke: variables.$basic-text-recessive;
    }

    &-text,
    &-icon {
      text-decoration: none;
      font-size: functions.rem(18px);
      line-height: functions.rem(18px);
      @include mixins.font-weight(medium);
      text-align: center;
    }

    &-active {
      color: variables.$basic-text;
      background-color: variables.$basic-background;

      svg {
        fill: variables.$basic-text;
        stroke: variables.$basic-text;
      }

      &::before {
        content: '';
        position: absolute;
        background-color: variables.$basic-accent;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: functions.rem(2px);
        margin: 0 auto;
      }
    }

    &-disabled {
      opacity: 0.5;
      cursor: default;
    }

    &-ripple {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      overflow: hidden;
      border-radius: inherit;
    }

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      border-radius: inherit;
      background-color: variables.$basic-accent;
      opacity: 0;
      transition: opacity 100ms variables.$standard-easing, background-color 100ms variables.$standard-easing;
      z-index: 2;
    }

    &:not([disabled]):hover:not(.cdk-keyboard-focused):not(.cdk-mouse-focused):not(.cdk-program-focused) {
      .box-nav-item-overlay {
        opacity: 0.04;
      }
    }

    &:not([disabled]):focus,
    &:not([disabled]).cdk-keyboard-focused,
    &:not([disabled]).cdk-mouse-focused,
    &:not([disabled]).cdk-program-focused {
      .box-nav-item-overlay {
        opacity: 0.12;
      }
    }
  }
}
