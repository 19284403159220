@use 'abstracts/variables';
@use 'abstracts/mixins';
@use 'abstracts/functions';

.box-divider {
  display: block;
  @include mixins.box-separator();
  margin: functions.rem(8px) 0;
  border-radius: functions.rem(1px);
}

.box-hr {
  position: relative;
  display: block;
  border: none;
  height: functions.rem(1px);
  border-radius: functions.rem(1px);
  background-color: variables.$basic-stroke-subtle;
  margin: functions.rem(8px) 0;

  &::after {
    content: attr(data-title);
    position: absolute;
    @include mixins.font-weight(bold);
    font-size: 0.875rem;
    font-style: italic;
    line-height: 1rem;
    padding: 0 1.5rem;
    color: variables.$basic-text-recessive;
    background-color: variables.$basic-background;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
