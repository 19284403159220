@use 'abstracts/variables';
@use 'abstracts/mixins';
@use 'abstracts/functions';

.box-expansion-panel {
  background-color: transparent;

  .mat-expansion-panel-header {
    height: functions.rem(32px);

    @include mixins.media-breakpoint-min(variables.$media-xl) {
      height: functions.rem(48px);
    }
  }
}
