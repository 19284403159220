@use 'abstracts/variables';

@mixin animation-keyframes() {
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-0.0625rem, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(0.125rem, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-0.25rem, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(0.25rem, 0, 0);
    }
  }

  @keyframes short-shake {
    33% {
      transform: translate3d(-0.125rem, 0, 0);
    }

    66% {
      transform: translate3d(0.125rem, 0, 0);
    }
  }

  @keyframes skeleton-progress {
    from {
      background-position: -#{variables.$skeleton-animation-width} 0;
    }

    to {
      background-position: calc(#{variables.$skeleton-animation-width} + 100%) 0;
    }
  }

  @keyframes rewardsBalanceBadgeTextAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}

@mixin slide-in-from-left($iteration-count: 1) {
  @keyframes slideInFromLeft {
    0% {
      margin-left: -100%;
    }
    100% {
      margin-left: 0;
    }
  }
  animation-name: slideInFromLeft;
  animation-duration: 3s;
  animation-timing-function: variables.$decelerate-easing;
  animation-delay: 0ms;
  animation-iteration-count: $iteration-count;
}

@mixin fade-in-element() {
  @keyframes flickerAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: flickerAnimation 0.82s;
}

@mixin shake-element($shake-type: 'long', $delay: 0) {
  @if ($shake-type == 'long') {
    animation: shake 0.82s;
  } @else {
    animation: short-shake 0.4s;
  }
  animation-delay: $delay;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.36, 0.07, 0.19, 0.97);
  animation-fill-mode: both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
