@use 'abstracts/variables';
@use 'abstracts/mixins';
@use 'abstracts/functions';

%bordered-button {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    border-color: inherit;
    border-style: solid;
    border-width: functions.rem(2px);
  }
}

%disabled-button {
  opacity: 0.5;
  pointer-events: none;
  box-shadow: none;
  cursor: default;
}

.box-button {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  background-color: variables.$basic-accent;
  color: variables.$basic-text-on-accent;
  border: none;
  border-radius: variables.$radius-extra-small;
  padding: 0 functions.rem(16px);
  @include mixins.font-weight(medium);
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  text-align: center;
  margin: 0;
  cursor: pointer;
  transition: opacity 0.2s variables.$standard-easing, box-shadow 0.2s variables.$standard-easing;

  &.box-button-regular {
    height: 2.75rem;
    font-size: 1.125rem;
    line-height: 2.75rem;
  }

  &.box-button-small {
    height: 2rem;
    font-size: 0.875rem;
    line-height: 2rem;
    padding: 0 1rem;
  }

  &.box-button-border-none::after {
    content: none;
  }

  &[disabled] {
    @extend %disabled-button;
  }

  // todo refactor do you really need this?
  .box-button-wrapper {
    position: relative;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    margin: auto;
    z-index: 1;
    width: 100%;
  }

  .box-button-ripple {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    overflow: hidden;
    border-radius: inherit;
  }

  .box-button-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
    border-radius: inherit;
    background-color: variables.$basic-accent;
    opacity: 0;
    transition: opacity 0.1s variables.$standard-easing, background-color 0.1s variables.$standard-easing;
    z-index: 2;
  }

  &:hover:not(.box-button-main):not(.cdk-keyboard-focused):not(.cdk-program-focused) {
    .box-button-overlay:not([disabled]) {
      opacity: 0.04;
    }
  }

  &.cdk-keyboard-focused,
  &.cdk-program-focused {
    .box-button-overlay:not([disabled]) {
      opacity: 0.12;
    }
  }

  &.box-button-primary {
    background-color: variables.$basic-accent;
    color: variables.$basic-text-on-accent;

    .box-button-overlay {
      background-color: variables.$hover-accent-hover;
    }
  }

  &.box-button-secondary {
    @extend %bordered-button;
    background-color: variables.$basic-background;
    color: variables.$basic-text-dominant;

    .box-button-overlay {
      background-color: variables.$hover-stroke-hover;
    }
  }

  &.box-button-muted {
    color: variables.$basic-text-recessive;
    background-color: variables.$basic-background-subtle;

    .box-button-overlay {
      background-color: variables.$light-gray;
    }
  }

  &.box-button-insignificant {
    color: variables.$basic-text-dominant;
    background-color: transparent;

    .box-button-overlay {
      background-color: variables.$hover-text-dominant-hover;
    }
  }
}
