@use 'abstracts/variables';
@use 'abstracts/mixins';
@use 'abstracts/functions';

.box-bottom-sheet {
  &.mat-bottom-sheet-container {
    padding: 0;
    max-height: 80vh;
    border-radius: variables.$radius-small variables.$radius-small 0 0;
    overflow-x: hidden;
    box-shadow: variables.$elevation7;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: inherit;
  }

  &-header {
    position: relative;
    flex-shrink: 0;
    z-index: 1;
    transition: box-shadow 50ms variables.$standard-easing;

    @include mixins.box-header-divider();

    &.borderless {
      &::after {
        content: none;
      }
    }

    &.has-shadow {
      box-shadow: variables.$elevation1;

      &:not(.borderless)::after {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &-content {
    display: block;
    flex: 1;
    min-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 0;
  }
}

.box-bottom-sheet-full-width {
  &.mat-bottom-sheet-container-large,
  &.mat-bottom-sheet-container-medium {
    max-width: 100%;
    width: 100vw;
    overflow-x: hidden;
  }
}
