@use 'sass:color';

$basic-background: var(--mode-basic-background);
$basic-text: var(--mode-basic-text);
$inverted-basic-text: var(--mode-inverted-basic-text);
$basic-stroke: var(--mode-basic-stroke);
$basic-stroke-subtle: var(--mode-basic-stroke-subtle);
$basic-accent-subtle: var(--mode-basic-accent-subtle);
$disabled-text: var(--colours-interaction-states-disabled-text-disabled);
$basic-accent: var(--mode-basic-accent);
$basic-text-on-accent: var(--mode-basic-text-on-accent);
$basic-text-on-accent-linear: var(--mode-basic-text-on-accent-linear);
$basic-background-subtle: var(--mode-basic-background-subtle);
$basic-text-recessive: var(--mode-basic-text-recessive);
$basic-modal-overlay: var(--mode-basic-modal-overlay);
$radius-zero: var(--radius-zero);
$radius-extra-small: var(--radius-extra-small);
$radius-small: var(--radius-small);
$radius-medium: var(--radius-medium);
$functional-success-standard: var(--colours-functional-success-standard);
$functional-warning-standard: var(--mode-functional-warning-standard);
$functional-warning-subtle: var(--mode-functional-warning-subtle);
$functional-notification-notification: var(--mode-functional-notification-notification);
$inverted-functional-notification-text-on-notification: var(--colours-functional-notification-text-on-notification);
$shades-neutral-shades-100: var(--mode-shades-neutral-shades-100);
$shades-neutral-shades-200: var(--mode-shades-neutral-shades-200);
$shades-neutral-shades-300: var(--mode-shades-neutral-shades-300);
$shades-neutral-shades-400: var(--mode-shades-neutral-shades-400);
$shades-neutral-shades-500: var(--mode-shades-neutral-shades-500);
$shades-neutral-shades-700: var(--mode-shades-neutral-shades-700) !default;
$shades-neutral-shades-800: var(--mode-shades-neutral-shades-800);
$inverted-shades-neutral-shades-600: var(--mode-inverted-shades-neutral-shades-600);
$shades-accent-shades-accent-subtle: var(--mode-shades-accent-shades-accent-subtle);
$hover-accent-hover: var(--colours-interaction-states-hover-accent-hover);
$basic-text-dominant: var(--mode-basic-text-dominant);
$basic-text-link: var(--mode-basic-text-link);
$interaction-states-disabled-text-dominant-disabled: var(--mode-interaction-states-disabled-text-dominant-disabled);
$interaction-states-disabled-text-recessive-disabled: var(--mode-interaction-states-disabled-text-recessive-disabled);
$hover-stroke-hover: var(--colours-interaction-states-hover-stroke-hover);
$functional-destructive-standard: var(--colours-functional-destructive-standard);
$hover-text-dominant-hover: var(--colours-interaction-states-hover-text-dominant-hover);
$inverted-functional-success-standard: var(--mode-inverted-functional-success-standard);
$inverted-functional-destructive-standard: var(--mode-inverted-functional-destructive-standard);
$inverted-shades-secondary-accent-shades-secondary-accent-dominant: var(
  --mode-inverted-shades-secondary-accent-shades-secondary-accent-dominant
);
$interaction-states-hover-stroke-subtle-hover: var(--mode-interaction-states-hover-stroke-subtle-hover);
$shades-accent-shades-accent-extra-dominant: var(--mode-shades-accent-shades-accent-extra-dominant);
$inverted-functional-warning-standard: var(--mode-inverted-functional-warning-standard);
$interaction-states-pressed-background-pressed: var(--mode-interaction-states-pressed-background-pressed);
$functional-warning-hover: var(--mode-functional-warning-hover);
// $elevation4 should be used instead of $elevation5 if seen on designs
// $elevation5 is missing from the Design System.
$elevation1: var(--mode-elevation-level-1);
$elevation2: var(--mode-elevation-level-2);
$elevation3: var(--mode-elevation-level-3);
$elevation4: var(--mode-elevation-level-4);
$elevation5: var(--mode-elevation-level-5);
$elevation6: var(--mode-elevation-level-6);
$elevation7: 0px 0px 4px 0px #4c516040;

// Deprecated
$white: #fff;
$black: #2a292e;
$primary-color: #ff8600;

// todo: remove
$light-gray: #f7f7f7;
$yellow: #ffb522;

// todo: data schemes update from design
$cosmote-primary: #00b40a;
$whats-up-primary: #1e1e1e;
$whats-up-secondary: #fff055;
$green-mid-saturation: #7bbdaa;
$green-low-saturation: #b3bdba;

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

// http://mcg.mbitson.com/#!?mcgpalette0=%23bf2296&themename=mcgtheme

/* Easing Functions */
$standard-easing: cubic-bezier(0.4, 0, 0.2, 1);
$accelerate-easing: cubic-bezier(0.4, 0, 1, 1);
$decelerate-easing: cubic-bezier(0, 0, 0.2, 1);

/* Media Breakpoints */
$media-sm: 36rem; // 576px
$media-md: 48rem; // 768px
$media-lg: 62rem; // 992px
$media-lg-2: 64rem; // 1024px
$media-xl: 75rem; // 1200px

/* Fonts */
$base-font: 'PF DIN Text Pro';
$fallback-font: Arial, sans-serif;
$base-font-weight: 400;
$base-font-size: 16px;
$base-line-height: 1.15;

/* Skeleton Animation */
$skeleton-animation-width: 12.5rem;

/* Pages */
$box-header-height: 8rem;
$box-header-height-mobile: 5.5rem;
$box-footer-mobile-menu-height: 3.875rem; // 62px

/* Radius */
/* Added the level 3 since it seems its a big number. We should add the remaining
border radius aftere we get the design system */
$border-radius-level-3: 4px;
$main-view-tile-rating-radius: 0 2px 2px 0;
$main-view-tile-left: 2px 0 0 2px;
$main-view-tile-delivery-time-radius: 0 0 2px 0;

$dialog-transition-duration: 150ms;
