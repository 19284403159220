@use 'abstracts/variables';
@use 'abstracts/functions';
@use 'abstracts/mixins';

.circular-progress {
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
  background: conic-gradient(
    var(--filled-perimeter-color, variables.$basic-accent) var(--degrees, 0deg),
    var(--empty-perimeter-color, variables.$basic-stroke-subtle) 0deg
  );
  border-radius: 50%;

  &::after {
    content: var(--content, '');
    height: 90%;
    width: 90%;
    border-radius: 50%;
    background-color: variables.$basic-background-subtle;
    color: var(--content-color, variables.$basic-text);
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    @include mixins.font-weight(medium);
    font-size: functions.rem(12px);
    line-height: functions.rem(14px);
  }
}
