@use 'sass:map';
@use 'sass:list';
@use 'sass:math';
@use 'sass:string';

@use '@angular/material' as mat;
@use './functions';
@use './variables';

/* Generic */

@mixin maximum-lines-of-text($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Media breakpoints */

@mixin media-breakpoint-max($max-width) {
  @media only screen and (max-width: $max-width) {
    @content;
  }
}

@mixin media-breakpoint-between($min-width, $max-width) {
  @media only screen and (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}

@mixin media-breakpoint-only($max-width) {
  @if $max-width == variables.$media-sm {
    @media only screen and (max-width: variables.$media-sm) {
      @content;
    }
  } @else if $max-width == variables.$media-md {
    @include media-breakpoint-between(variables.$media-sm, variables.$media-md) {
      @content;
    }
  } @else if $max-width == variables.$media-md {
    @include media-breakpoint-between(variables.$media-md, variables.$media-lg) {
      @content;
    }
  } @else if $max-width == variables.$media-lg {
    @include media-breakpoint-between(variables.$media-lg, variables.$media-xl) {
      @content;
    }
  } @else if $max-width == variables.$media-xl {
    @media only screen and (min-width: variables.$media-xl) {
      @content;
    }
  }
}

@mixin media-breakpoint-min($max-width) {
  @media only screen and (min-width: $max-width) {
    @content;
  }
}

/* BOX Theme */
@mixin box-anchor-element($type: '') {
  @if $type == 'telephone' {
    color: variables.$basic-text;
    text-decoration: underline;
  } @else {
    @include font-weight(medium);
    color: variables.$basic-text-dominant;
  }
}

@mixin dialog-cta-wrapper() {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: functions.rem(72px);
  padding: functions.rem(16px) 0;
  background: variables.$basic-text-on-accent-linear;
}

@mixin box-button-theme($theme) {
  @include mat.button-theme($theme);

  .mat-flat-button {
    &.box-button {
      font-size: 1rem;
      border-radius: variables.$radius-zero;
      border: 0.125rem solid rgba(48, 52, 54, 0.5);

      &:disabled {
        opacity: 0.5;

        &.mat-primary {
          background-color: variables.$basic-accent;
        }

        &.mat-accent {
          background-color: variables.$inverted-functional-success-standard;
        }

        &.mat-warn {
          background-color: variables.$inverted-functional-destructive-standard;
        }
      }
    }
  }
}

@mixin header-bottom-img($height, $value) {
  position: absolute;
  background-color: variables.$basic-background;
  -webkit-mask-image: url('/assets/images/general/header-mask-horizontal.svg');
  mask-image: url('/assets/images/general/header-mask-horizontal.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: #{$height}#{$value};
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
}

@mixin header-left-img($width, $value) {
  position: absolute;
  background-image: url('/assets/images/general/header-mask-vertical.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: #{$width}#{$value};
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
}

@mixin box-section() {
  padding: functions.rem(32px);

  @include media-breakpoint-min(variables.$media-xl) {
    padding: functions.rem(48px);
  }
}

@mixin box-container() {
  display: block;
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  width: auto;

  @include media-breakpoint-only(variables.$media-lg) {
    max-width: 60rem;
  }

  @include media-breakpoint-only(variables.$media-xl) {
    max-width: 78rem;
  }
}

/* Font Weight */

@mixin font-weight($weight) {
  $weights: (
    extra-thin: 100,
    ultra-thin: 100,
    thin: 200,
    light: 300,
    book: 400,
    regular: 400,
    medium: 500,
    bold: 600,
    extra-bold: 900,
    ultra-bold: 900,
    heavy: 900,
    black: 900,
    ultra: 900,
    ultra-black: 900,
    extra-ultra: 900
  );

  $output: $weight;
  @if map.has-key($weights, $weight) {
    $output: map.get($weights, $weight);
  }

  font-weight: $output;
}

@mixin box-header($size) {
  @if $size == 1 {
    @include font-weight(medium);
    font-size: functions.rem(28px);
    line-height: functions.rem(32px);
    @include media-breakpoint-min(variables.$media-md) {
      font-size: functions.rem(36px);
      line-height: functions.rem(40px);
    }
    @include media-breakpoint-min(variables.$media-lg) {
      font-size: functions.rem(38px);
      line-height: functions.rem(48px);
    }
    @include media-breakpoint-min(variables.$media-xl) {
      font-size: functions.rem(40px);
      line-height: functions.rem(50px);
    }
  } @else if $size == 2 {
    // font-size: 2.5rem;
    @include font-weight(medium);
    font-size: functions.rem(28px);
    line-height: functions.rem(30px);
    @include media-breakpoint-min(variables.$media-lg) {
      font-size: functions.rem(30px);
      line-height: functions.rem(32px);
    }
    @include media-breakpoint-min(variables.$media-xl) {
      font-size: functions.rem(32px);
      line-height: functions.rem(34px);
    }
  } @else if $size == 3 {
    @include font-weight(medium);
    font-size: functions.rem(24px);
    line-height: 1.125;
  } @else if $size == 4 {
    @include font-weight(medium);
    font-size: functions.rem(18px);
    line-height: functions.rem(20px);
  } @else if $size == 5 {
    font-size: 1.25rem;
  } @else if $size == 6 {
    font-size: 1rem;
  } @else if $size == swiper-section-title {
    font-size: functions.rem(20px);
    line-height: functions.rem(24px);
    @include font-weight(bold);
  } @else {
    @error "Unknown BOX Header Size #{$size}.";
  }
}

@mixin box-text($type) {
  @if $type == instructions {
    font-size: 0.875rem;
    line-height: 1rem;
    @include font-weight(regular);
    text-align: center;
    color: variables.$shades-neutral-shades-400;
    padding: 1rem;
  } @else if $type == instructions-recessive {
    font-size: 0.875rem;
    line-height: 1rem;
    @include font-weight(regular);
    text-align: center;
    color: variables.$basic-text-recessive;
    padding: 1rem;
  } @else if $type == instructions-basic {
    @include font-weight(regular);
    font-size: functions.rem(16px);
    line-height: functions.rem(18px);
    color: variables.$basic-text;
    text-align: center;
  } @else if $type == dialog-content {
    font-size: 0.875rem;
    line-height: 1rem;
    @include font-weight(regular);
    text-align: center;
    color: variables.$basic-text;
    padding: 1rem;
  } @else if $type == section-title {
    font-size: 1.25rem;
    line-height: 1.5rem;
    @include font-weight(medium);
    color: variables.$basic-text;
  } @else if $type == section-sub-title {
    font-size: 0.875rem;
    line-height: 1rem;
    text-align: center;
    @include font-weight(bold);
    color: variables.$basic-text;
  } @else if $type == category-name {
    @include font-weight(medium);
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: variables.$basic-text;
  } @else if $type == subcategory-name {
    @include font-weight(regular);
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: variables.$basic-text-recessive;
  } @else {
    @error "Unknown BOX Text Type #{$type}.";
  }
}

@mixin product-text($type) {
  @if $type == title {
    font-size: functions.rem(14px);
    line-height: functions.rem(16px);
    @include font-weight(bold);
    color: variables.$basic-text;
  } @else if $type == description {
    font-size: functions.rem(14px);
    line-height: functions.rem(16px);
    @include font-weight(light);
    color: variables.$basic-text-recessive;
  } @else if $type == measurement-unit {
    font-size: functions.rem(14px);
    line-height: functions.rem(16px);
    @include font-weight(light);
    color: variables.$basic-text;
  } @else if $type == starting-price {
    font-size: functions.rem(14px);
    line-height: functions.rem(16px);
    @include font-weight(regular);
    color: variables.$basic-text-recessive;
    text-decoration: line-through;
  } @else if $type == price {
    font-size: functions.rem(16px);
    line-height: functions.rem(20px);
    @include font-weight(medium);
    color: variables.$basic-text;
  } @else {
    @error "Unknown Product Text Type #{$type}.";
  }
}

/* BOX Images */

@mixin box-figure($fit: '') {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    border-radius: inherit;

    @if string.unquote($fit) == cover {
      object-position: center;
      object-fit: cover;
    } @else if string.unquote($fit) == contain {
      object-position: center;
      object-fit: contain;
    }
  }
}

/* Image Slice */
@mixin image-slice-right($width: 16px) {
  position: relative;
  flex-shrink: 0;

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: functions.rem($width);
    right: 0;
    top: 0;
    background-image: url('/assets/images/general/image-slices-right.svg');
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

/* Environmental Fee Text */
@mixin env-fee() {
  opacity: 0.8;
  @include font-weight(regular);
  font-size: functions.rem(12px);
  color: variables.$basic-text-recessive;
  line-height: functions.rem(14px);
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;

  &::before {
    content: '';
    mask-image: url('/assets/images/shops/env-fee.svg');
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: variables.$basic-text-recessive;
    min-width: functions.rem(14px);
    min-height: functions.rem(14px);
    margin-right: functions.rem(5px);
  }
}

/* Hoverable */

@mixin hoverable-element() {
  transition: background-color 100ms variables.$standard-easing;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  cursor: pointer;
  border-radius: variables.$radius-extra-small;

  &:hover,
  &:focus {
    background-color: variables.$basic-background-subtle;
  }
}

@mixin hover-opacity() {
  transition: opacity 100ms variables.$standard-easing;
  &:hover {
    opacity: 0.85;
  }
}
@mixin hoverable-animated-element() {
  transform: scale(1.025);
  transition: all 0.25s ease-in-out;
}

/* Skeleton */

@mixin skeleton-element() {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  display: block;
  animation: 1.5s ease-in-out infinite skeleton-progress;
  background: 0 list.slash(0, variables.$skeleton-animation-width) 100% no-repeat variables.$shades-neutral-shades-200;
  background-image: linear-gradient(
    90deg,
    color-mix(in srgb, variables.$basic-background 0%, transparent),
    color-mix(in srgb, variables.$basic-background 60%, transparent),
    color-mix(in srgb, variables.$basic-background 0%, transparent)
  );
  border-radius: variables.$radius-extra-small;
}

@mixin box-header-divider() {
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: functions.rem(1px);
    margin: 0 functions.rem(16px);
    background-color: variables.$basic-stroke-subtle;
    transition:
      visibility 0s,
      opacity 50ms variables.$standard-easing;
    visibility: visible;
    opacity: 1;
    z-index: -1;
  }
}

@mixin wave-image($path, $name, $ext) {
  // Default fallback rule in case of Browser Support
  background-image: url('#{$path}@2x#{$name}.#{$ext}');
  // We support 1x, 2x and 3x Pixel Density
  @for $index from 1 through 3 {
    @media (-webkit-min-device-pixel-ratio: $index) {
      background-image: url('#{$path}@#{$index}x#{$name}.#{$ext}');
    }
  }
}

/* Sticky */

@mixin sticky-element($top: 48px) {
  position: sticky;
  top: functions.rem($top);
}

/* Parallax */

$parallax-default-depth: 1px;
$parallax-default-distance: -2px;
$parallax-default-bg-z-index: -10;

@function parallax-scale-factor($perspective-depth, $z-distance) {
  @return math.div($perspective-depth - $z-distance, $perspective-depth);
}

@mixin parallax-scrollable-container($perspective-depth: $parallax-default-depth) {
  // element must be scrollable
  perspective: $perspective-depth;
  perspective-origin: top left;
  transform: translateZ(0px);
}

@mixin parallax-middle-element() {
  // every element between container and foreground or background
  transform-style: preserve-3d;
}

@mixin parallax-fixed-foreground() {
  // foreground and background can be siblings
  transform: translateZ(0px);
}

@mixin parallax-distant-background(
  $perspective-depth: $parallax-default-depth,
  $distance: $parallax-default-distance,
  $z-index: $parallax-default-bg-z-index
) {
  transform: translateZ($distance) scale(parallax-scale-factor($perspective-depth, $distance));
  transform-origin: top left;
  z-index: $z-index;
}

@mixin box-separator($distance: 40px) {
  margin: functions.rem(8px + $distance) 0;
  border-bottom: functions.rem(1px) solid variables.$basic-stroke-subtle;
}

@mixin badge-text() {
  line-height: functions.rem(22px);
  padding: 0 functions.rem(4px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin notification-wrapper() {
  border-radius: variables.$radius-medium;
  background-color: variables.$basic-stroke-subtle;
  box-shadow: variables.$elevation5;
}

@mixin subtle-border() {
  border-radius: variables.$radius-extra-small;
  border: functions.rem(1px) solid variables.$basic-stroke-subtle;
}

@mixin accent-border() {
  border-radius: variables.$radius-extra-small;
  border: functions.rem(1px) solid variables.$basic-stroke;
}

@mixin dialog-border() {
  border-radius: variables.$radius-small;
  border: functions.rem(2px) solid variables.$basic-stroke-subtle;
}

@mixin fade-out($direction, $color) {
  background: linear-gradient(
    $direction,
    color-mix(in srgb, $color 0%, transparent),
    color-mix(in srgb, $color 20%, transparent),
    color-mix(in srgb, $color 40%, transparent),
    color-mix(in srgb, $color 60%, transparent),
    color-mix(in srgb, $color 80%, transparent),
    color-mix(in srgb, $color 100%, transparent)
  );
}
