@use '../abstracts/functions';
@use '../abstracts/mixins';

@mixin box-text-skeleton-before {
  &::before {
    content: '\00a0';
  }
}

.h1 {
  &--skeleton {
    @include mixins.box-header(1);
    @include mixins.skeleton-element();
    width: 100%;
    max-width: functions.rem(360px);
    @include box-text-skeleton-before();
  }
}

.h2 {
  &--skeleton {
    @include mixins.box-header(2);
    @include mixins.skeleton-element();
    width: 100%;
    max-width: functions.rem(360px);
    @include box-text-skeleton-before();
  }
}

.h3 {
  &--skeleton {
    @include mixins.box-header(3);
    @include mixins.skeleton-element();
    width: 100%;
    max-width: functions.rem(360px);
    @include box-text-skeleton-before();
  }
}

.h4 {
  &--skeleton {
    @include mixins.box-header(4);
    @include mixins.skeleton-element();
    width: 100%;
    max-width: functions.rem(360px);
    @include box-text-skeleton-before();
  }
}

.h5 {
  &--skeleton {
    @include mixins.box-header(5);
    @include mixins.skeleton-element();
    width: 100%;
    max-width: functions.rem(360px);
    @include box-text-skeleton-before();
  }
}

.h6 {
  &--skeleton {
    @include mixins.box-header(6);
    @include mixins.skeleton-element();
    width: 100%;
    max-width: functions.rem(360px);
    @include box-text-skeleton-before();
  }
}

.p {
  &--skeleton {
    @include mixins.skeleton-element();
    @include box-text-skeleton-before();

    &:last-child:not(:first-child) {
      width: 80%;
    }
  }
}
