@use 'abstracts/mixins';

.hoverable {
  @include mixins.hoverable-element();
}

.hover-opacity {
  @include mixins.hover-opacity();
}

.hoverable-animated {
  &:hover {
    @include mixins.hoverable-animated-element();
  }
}
