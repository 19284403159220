@use '../abstracts/variables';
@use '../abstracts/functions';
@use '../abstracts/mixins';

@mixin core-styles() {
  .output-block {
    font-size: functions.rem(16px);
    line-height: functions.rem(18px);

    &-header {
      font-size: functions.rem(20px);
      line-height: functions.rem(24px);
      @include mixins.font-weight(bold);

      @include mixins.media-breakpoint-min(variables.$media-sm) {
        font-size: functions.rem(24px);
        line-height: functions.rem(28px);
      }
    }

    a {
      @include mixins.box-anchor-element();
    }
  }
}
