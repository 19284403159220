@use 'abstracts/mixins';
@use 'abstracts/functions';

@mixin core-styles() {
  .swiper-section {
    &-header {
      display: flex;
      align-items: center;
      margin-bottom: functions.rem(16px);

      &-small-image {
        height: functions.rem(24px);
        width: functions.rem(24px);
        margin-right: functions.rem(8px);
        flex-shrink: 0;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
          object-position: center;
        }
      }

      &-content {
        display: flex;
        align-items: center;
      }
    }

    &-title {
      @include mixins.box-header(swiper-section-title);
      margin-bottom: 0;
      white-space: nowrap;
    }

    &-navigation {
      display: flex;
      margin-left: auto;
      flex-shrink: 0;

      &.hidden {
        opacity: 0;
      }
    }
  }
}
