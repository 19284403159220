@use 'abstracts/variables';
@use 'abstracts/functions';
@use 'abstracts/mixins';

.box-preloader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: functions.rem(224px);
  width: 100%;
  padding: 1rem;

  @include mixins.media-breakpoint-max(variables.$media-sm) {
    height: functions.rem(160px);
    width: functions.rem(160px);
  }

  &:after {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background-image: url('/assets/animations/box-loading_226x226.gif');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    background-color: variables.$basic-accent;
    z-index: 1000;
  }
}
