@use 'abstracts/mixins';
@use 'abstracts/functions';
@use 'abstracts/variables';

.cdk-overlay-pane {
  .mat-mdc-dialog-container {
    padding: 0;
    max-height: 90vh;
    @include mixins.dialog-border();
    overflow-x: hidden;
    overflow-y: hidden;
    animation: fadeIn variables.$dialog-transition-duration ease-out;

    @include mixins.media-breakpoint-max(variables.$media-sm) {
      max-height: 100vh;
      max-width: 100vw;
      height: unset;
    }
  }
}

.box-dialog {
  max-width: 38rem !important;
  width: 100%;

  .mat-mdc-dialog-container {
    max-height: 80vh;
    overflow: hidden;

    @include mixins.media-breakpoint-max(variables.$media-sm) {
      max-height: unset;
      height: unset;
    }
  }

  &-small {
    max-width: 30rem !important;
    width: 100%;
  }

  &-medium {
    max-width: 38rem !important;
    width: 100%;
  }

  &-small-with-large-buttons {
    max-width: functions.rem(407px) !important;
    max-height: functions.rem(306px) !important;

    .box-button {
      width: functions.rem(279px) !important;
    }
  }

  &--large {
    max-width: functions.rem(1024px) !important;
    width: 100%;
  }

  &-fit-content {
    max-width: 46.875rem !important;

    &.no-background {
      .mat-mdc-dialog-container {
        background: transparent;
        box-shadow: none;

        .mat-mdc-dialog-surface,
        .mat-mdc-menu-panel,
        .mat-mdc-select-panel {
          background-color: transparent !important;
        }
      }
    }

    &.borderless {
      .mat-mdc-dialog-container {
        border: none;
      }
    }
  }
}

.box-dialog-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: inherit;
}

.box-dialog-header {
  position: relative;
  flex-shrink: 0;
  z-index: 1;
  transition: box-shadow 50ms variables.$standard-easing;

  @include mixins.box-header-divider();

  &.borderless {
    &::after {
      content: none;
    }
  }

  &.has-shadow {
    &:not(.borderless)::after {
      visibility: hidden;
      opacity: 0;
    }
  }

  &.image-transition {
    &::before {
      @include mixins.header-bottom-img(2.5, 'rem');
      content: '';
      top: -2.5rem;
    }
  }
}

.box-dialog-footer {
  flex-shrink: 0;
  z-index: 1;
  transition: box-shadow 50ms variables.$standard-easing;
}

.box-dialog-content {
  display: block;
  flex: 1;
  min-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 0;
}
