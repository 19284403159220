@use 'abstracts/mixins';

/* Font Family */

@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-ExtraThin.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-ExtraThin.woff') format('woff');
  font-weight: 100;
  font-display: swap;
}

@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-ExtraThinItalic.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-ExtraThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-Thin.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-Thin.woff') format('woff');
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-ThinItalic.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-ThinItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-Light.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-Light.woff') format('woff');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-LightItalic.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-Regular.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-Regular.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-Italic.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

// matches with -m-bold, -s-bold
@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-Medium.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-Medium.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-Medium.woff') format('woff');
  font-weight: 700;
  font-size: 0.875rem;
  font-display: swap;
}

@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-MediumItalic.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-Black.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-Black.woff') format('woff');
  font-weight: 700;
  font-size: 1rem;
  font-display: swap;
}

@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-BoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-Bold.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-Bold.woff') format('woff');
  font-weight: 700;
  font-size: 0.75rem;
  font-display: swap;
}

@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-BoldItalic.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-BoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-BlackItalic.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-BlackItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-ExtraBlack.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-ExtraBlack.woff') format('woff');
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: 'PF DIN Text Pro';
  src: url('/assets/fonts/PFDinTextPro/PFDinTextPro-ExtraBlackItalic.woff2') format('woff2'),
    url('/assets/fonts/PFDinTextPro/PFDinTextPro-ExtraBlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Material Icons';
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url('/assets/fonts/material-icons/MaterialIcons-Regular.woff2') format('woff2'),
    url('/assets/fonts/material-icons/MaterialIcons-Regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Material Icons Round';
  src: local('Material Icons'), local('MaterialIcons-Round'),
    url('/assets/fonts/material-icons/MaterialIcons-Round.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

/* BOX Specific Texts */

h1 {
  @include mixins.box-header(1);
}

h2 {
  @include mixins.box-header(2);
}

h3 {
  @include mixins.box-header(3);
}

h4 {
  @include mixins.box-header(4);
}

h5 {
  @include mixins.box-header(5);
}

h6 {
  @include mixins.box-header(6);
}

/**
 The font-weight, font-size and font-style properties,
 uniquely identify the woff file that will be loaded from the font-face properties specified above.
 If 2 classes have a font-weight A, different font-sizes and there is only 1 font face specified for font weight A,
 the same font face will be loaded for both classes but the size attribute will overwrite the original font.
 If 2 classes have the same weight, size and style the same font-face will be loaded.
 */
// RADD
.ods-typography-display-l {
  font-weight: 700;
  font-size: 3.5rem;
  font-family: 'PF DIN Text Pro';
  line-height: 4rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-display-m {
  font-weight: 700;
  font-size: 2.5rem;
  font-family: 'PF DIN Text Pro';
  line-height: 3.125rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-display-s {
  font-weight: 700;
  font-size: 2rem;
  font-family: 'PF DIN Text Pro';
  line-height: 2rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-title-l {
  font-weight: 700;
  font-size: 1.5rem;
  font-family: 'PF DIN Text Pro';
  line-height: 1.75rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-title-m {
  font-weight: 700;
  font-size: 1.25rem;
  font-family: 'PF DIN Text Pro';
  line-height: 1.5rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-title-s {
  font-weight: 700;
  font-size: 1.125rem;
  font-family: 'PF DIN Text Pro';
  line-height: 1.5rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-subtitle-l {
  font-weight: 500;
  font-size: 1.125rem;
  font-family: 'PF DIN Text Pro';
  line-height: 1.5rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-subtitle-m {
  font-weight: 400;
  font-size: 1.125rem;
  font-family: 'PF DIN Text Pro';
  line-height: 1.5rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-subtitle-s {
  font-weight: 700;
  font-size: 1rem;
  font-family: 'PF DIN Text Pro';
  line-height: 1.125rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-body-m-bold {
  font-weight: 500; // manually updated to load the medium font
  font-size: 1rem;
  font-family: 'PF DIN Text Pro';
  line-height: 1.125rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-body-m-regular {
  font-weight: 400;
  font-size: 1rem;
  font-family: 'PF DIN Text Pro';
  line-height: 1.125rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-body-s-bold {
  font-weight: 500; // manually updated to load the medium font
  font-size: 0.875rem;
  font-family: 'PF DIN Text Pro';
  line-height: 1rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-body-s-regular {
  font-weight: 400;
  font-size: 0.875rem;
  font-family: 'PF DIN Text Pro';
  line-height: 1rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-button-l {
  font-weight: 500;
  font-size: 1.125rem;
  font-family: 'PF DIN Text Pro';
  line-height: 1.25rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-button-m {
  font-weight: 500;
  font-size: 1rem;
  font-family: 'PF DIN Text Pro';
  line-height: 1rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-button-s {
  font-weight: 500;
  font-size: 0.75rem;
  font-family: 'PF DIN Text Pro';
  line-height: 0.875rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-microcopy-bold {
  font-weight: 700;
  font-size: 0.75rem;
  font-family: 'PF DIN Text Pro';
  line-height: 0.875rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-microcopy-regular {
  font-weight: 400;
  font-size: 0.75rem;
  font-family: 'PF DIN Text Pro';
  line-height: 0.875rem;
  letter-spacing: 0;
  text-decoration: none;
}
.ods-typography-link-l {
  font-weight: 500;
  font-size: 1.125rem;
  font-family: 'PF DIN Text Pro';
  line-height: 1.25rem;
  letter-spacing: 0;
  text-decoration: underline;
}
.ods-typography-link-m {
  font-weight: 500;
  font-size: 1rem;
  font-family: 'PF DIN Text Pro';
  line-height: 1rem;
  letter-spacing: 0;
  text-decoration: underline;
}
.ods-typography-link-s {
  font-weight: 500;
  font-size: 0.875rem;
  font-family: 'PF DIN Text Pro';
  line-height: 1rem;
  letter-spacing: 0;
  text-decoration: underline;
}
