@use 'abstracts/variables';
@use 'abstracts/functions';
@use 'abstracts/mixins';

@mixin core-styles() {
  .badge {
    position: absolute;
    display: inline-block;
    color: variables.$inverted-basic-text;
    background-color: variables.$functional-notification-notification;
    border-radius: 50%;
    @include mixins.font-weight(medium);
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: transform 100ms variables.$accelerate-easing;
    pointer-events: none;

    &-small {
      font-size: functions.rem(10px);
      width: functions.rem(16px);
      height: functions.rem(16px);
      line-height: functions.rem(16px);
      top: functions.rem(-8px);
      right: functions.rem(-8px);
    }

    &-medium {
      font-size: functions.rem(14px);
      width: functions.rem(24px);
      height: functions.rem(24px);
      line-height: functions.rem(24px);
      top: functions.rem(-12px);
      right: functions.rem(-12px);
    }

    &-large {
      font-size: functions.rem(18px);
      width: functions.rem(28px);
      height: functions.rem(28px);
      line-height: functions.rem(28px);
      top: functions.rem(-14px);
      right: functions.rem(-14px);
    }

    &[hidden] {
      display: none;
    }

    &-container {
      position: relative;
      overflow: visible;
    }
  }
}
