.image {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }

  &.cover-image img {
    object-position: center;
    object-fit: cover;
    border-radius: inherit;
  }

  &.contain-image img {
    object-position: center;
    object-fit: contain;
    border-radius: inherit;
  }
}
