@use 'abstracts/variables';
@use 'abstracts/mixins';
@use 'abstracts/functions';

.box-banner {
  display: block;
  position: relative;
  height: 4rem;

  &-small {
    height: 3.5rem;
  }

  &-big {
    height: 4.5rem;
  }

  &-wrapper {
    display: flex;
    align-items: center;
    box-shadow: variables.$elevation1;
    border-radius: variables.$radius-extra-small;
    height: inherit;
    overflow: hidden;
  }

  &-image {
    @include mixins.box-figure(contain);
    width: 4.75rem;
    height: inherit;
    margin-right: 1rem;

    @include mixins.image-slice-right(12px);
  }

  &-content {
    display: flex;
    flex: 1 0 0;
    align-items: center;

    &-text {
      @include mixins.font-weight(medium);
      font-size: 0.875rem;
      line-height: 1rem;
      margin: 0;
    }
  }

  &-slide-container {
    padding: 0 1rem;
    flex-shrink: 0;
  }
}
